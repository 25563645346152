import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import PageLoading from '../src/UI/routing/PageLoading';
// import i18n (needs to be bundled ;))
import './i18n';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { LicenseKey } from 'unity-fluent-library';

LicenseManager.setLicenseKey(LicenseKey);

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div>
          <PageLoading />
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
