export enum GridEnums {
  Ideas = 'f3c1bf72-15fd-4dcd-904c-b67850525cff',
  Tickets = '787a2afc-26df-440a-9040-9a3e5c5333d5',
  Users = '4ea9fe5d-0af3-4102-af95-4d4a5ca886e1',
  Tenants = 'b543fc1d-6f45-469e-87ae-2b18b8200955',
  Roles = '9af2eebb-7687-4b3c-91fa-607efb0a5fea',
  Data_Browser = 'ac0b9a3a-d113-4590-93db-dea62bcb89a2',
  Groups = 'c599b6ff-fee3-4ade-bf78-16700fddb5b9',
}

export enum GridStateTypeEnums {
  Column = 1,
  Pivot_Mode = 2,
  Column_Sort = 3,
  Column_Filter = 4,
  Column_Group = 5,
}

export enum MenuTypeEnums {
  Left_Menu = 1,
  Application_Menu = 2,
  Builders_Menu = 3,
  Favorites_Menu = 4,
}

export enum VerticalEnums {
  Engage = 1,
  Manage = 2,
  Build = 3,
  Play = 4,
}

export enum MenuItemEnums {
  Container = 'b36cec3c-fe05-47ed-a687-70be22b0e200',
  External_Link = '11758dfd-ac3a-4996-b86c-767947776ff8',
  Data_Browser = 'afc3833e-e288-4cf6-8155-a069ec4ddc5a',
  Reporting = '22763e92-2477-43c3-92db-124a84e5ff7f',
  Page = 'd506aeef-ba1b-49a8-9032-beba450b11bc',
  Power_Bi = '16DF758B-C456-4C8C-8CA3-2AE167B3B078',
  Route = 'ec5f1c26-e7e1-4f19-8e6e-143308995a40',
}

export enum ADUserTypeEnums {
  Guest = 'Guest',
  Member = 'Member',
}

export enum ProductEnums {
  Unity = 1,
}

export enum RoleTypeEnums {
  System = 1,
  Tenant = 2,
}

export enum LookupTypeEnums {
  No_Lookup = 'NO_LOOKUP',
  Api = 'API',
  Csv = 'CSV',
}

export enum SearchValueType {
  String = 'String',
  Int = 'Int',
  Long = 'Long',
  Double = 'Double',
  Boolean = 'Boolean',
  DateTime = 'DateTime',
}

export enum SearchGroupingType {
  Distinct = 'DISTINCT',
  Aggregate = 'AGGREGATE',
}

export enum SearchSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export const ApiMethodType = {
  0: 'get',
  1: 'post',
  2: 'put',
  3: 'delete',
  6: 'patch',
};

export enum RoutePermissionEnums {
  Can_Access_Data_Browser = 'CAN_ACCESS_DATA_BROWSER',
}
