import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { apiMutate } from 'unity-fluent-library';
import * as ufl from 'unity-fluent-library';
console.log(apiMutate);
console.log(ufl);
i18n.on('languageChanged', lng => {
  if (!i18n.hasResourceBundle(lng, 'translation')) {
    apiMutate(
      process.env.REACT_APP_TENANTS_API_BASE,
      `translation/language/${lng}`,
      {
        method: 'get',
      }
    ).then(translatedLanguage => translatePage(translatedLanguage.data, lng));

    const translatePage = (languageData, lng) => {
      const languageObject = {};

      languageData.forEach(data => {
        languageObject[data.label] = data.value;
      });

      i18n.addResourceBundle(lng, 'translation', languageObject, true, true);

      // i18n doesn't reload after adding resource bundle, so this reloads it upon language selection
      // existing language bundle caught by if statement
      i18n.changeLanguage(lng);
    };
  }
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'en-US',
      detection: {
        order: ['queryString', 'cookie'],
        cache: ['cookie'],
      },
      interpolation: {
        escapeValue: false,
      },
    },
    err => {
      if (err) {
        console.log(err);
      }
    }
  );

export default i18n;
