import React from 'react';
import { useUser } from 'unity-fluent-library';
import PageForbidden from '../UI/routing/PageForbiddenRoute';
import { Route } from 'react-router-dom';

const RestrictedRoute = props => {
  const { restrictedTo, component, ...other } = props;
  const user = useUser();

  const userHasAccess = restrictedTo.find(restriction => user.can[restriction]);

  return (
    <Route component={userHasAccess ? component : PageForbidden} {...other} />
  );
};

export default RestrictedRoute;
