import React from 'react';
import { FluentDialog } from 'unity-fluent-library';

const ZeroTenantAccessDialog = props => {
  const { open } = props;

  return (
    <FluentDialog
      title={'No Access'}
      message={
        'Your user does not have access to any tenants. Please contact your support team.'
      }
      open={open}
      buttonOneHidden={true}
      buttonTwoHidden={true}
    />
  );
};

export default ZeroTenantAccessDialog;
