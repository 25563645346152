import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Switch,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import {
  Shell,
  useAxiosGet,
  useUser,
  useIsUnityAuthenticated,
  ActionProvider,
  useUserActions,
} from 'unity-fluent-library';
import { SnackbarProvider } from 'notistack';
import PageLoading from './UI/routing/PageLoading';
import AadCallback from './routes/callback/AadCallback';
import './animationStyles.css';
import { Theme, useTheme } from '@material-ui/core';
import LoadingIndicator from './utils/LoadingIndicator';
import ZeroTenantAccessDialog from './UI/routing/ZeroTenantAccessDialog';
import { getStoredTenant } from './utils/storage/UnitySessionStorage';
import AppBarControls from './appBarContent/AppBarControls';
import UserWrapper from './appBarContent/UserWrapper';
import ErrorBoundary from './ErrorBoundary';
import PrivateRoute from './routes/PrivateRoute';
import { useTranslation } from 'react-i18next';

const LocalLogin = lazy(() => import('./routes/auth/LocalLogin'));
const PageNotFound = lazy(() => import('./UI/routing/PageNotFoundRoute'));
const DataBuilder = lazy(() => import('./routes/dataBuilder/DataBuilder'));
const SavedQueries = lazy(
  () => import('./routes/dataBuilder/pages/SavedQueries')
);
const RecentQueries = lazy(
  () => import('./routes/dataBuilder/pages/RecentQueries')
);
const SharedQueries = lazy(
  () => import('./routes/dataBuilder/pages/SharedQueries')
);
const FavoriteQueries = lazy(
  () => import('./routes/dataBuilder/pages/FavoriteQueries')
);
const CatalogManager = lazy(() => import('./routes/CatalogManager/'));

interface CustomTheme {
  theme: Theme;
  setCurrentTheme: Function;
  isInitialized: boolean;
}

const IS_LOCAL_AUTH = process.env.REACT_APP_LOCAL_AUTH === 'true';

/**
 * Routes
 */
const Routes = (props: any) => {
  const { location } = props;
  const [zeroTenantAccess, setZeroTenantAccess] = useState(false);
  const user: any = useUser();

  const theme: CustomTheme = useTheme();
  const { setUser } = useUserActions();
  const isAuthenticated = useIsUnityAuthenticated();
  const { t, i18n } = useTranslation();

  const [{ data: leftMenu }]: any = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `menus?menuTypeId=1&productId=${process.env.REACT_APP_UNITY_PRODUCT_ID}&tenantId=${user?.currentTenantId}`,
    {},
    !!!user?.currentTenantId
  );

  const [{ data: appSelectorMenus }]: any = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `menus?tenantId=${user?.currentTenantId}&verticalId=${
      process.env.REACT_APP_UNITY_VERTICAL_ID
    }&userId=${user ? user?.id : ''}`,
    {},
    !!!user?.id
  );

  const [
    { data: currentTenant, error: getTenantError },
    refetchCurrentTenant,
  ]: any = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `tenants/${user?.currentTenantId}`,
    {},
    true
  );

  const [{ data: userTenants }]: any = useAxiosGet(
    process.env.REACT_APP_SECURITY_API_BASE,
    `users/${user ? user?.id : ''}/unitytenants`,
    {},
    !!!user?.id
  );

  const [{ data: languages }]: any = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `language`,
    {},
    !!!user?.id
  );

  const [{ data: defaultProductList }]: any = useAxiosGet(
    process.env.REACT_APP_TENANTS_API_BASE,
    `products`,
    {},
    !!!user?.id
  );

  const enableFilter = () => {
    if (defaultProductList != null) {
      let matchingProduct = defaultProductList.filter(
        prod =>
          prod.productId === Number(process.env.REACT_APP_UNITY_PRODUCT_ID)
      );
      if (matchingProduct[0].isSubscribable) {
        return filterTenants(userTenants);
      }
    }
    return userTenants;
  };

  const filterTenants = userTenants => {
    interface ValidTenant {
      tenantId: string;
    }

    let tenantMap = new Map();
    let containsProduct: ValidTenant[] = [];

    if (userTenants != null) {
      userTenants.forEach(tenant =>
        tenantMap.set(tenant.tenantId, tenant.products)
      );
    }
    tenantMap.forEach((value, key) => {
      let productArray: number[] = [];
      value.forEach(product => productArray.push(product.productId));
      if (
        productArray.includes(Number(process.env.REACT_APP_UNITY_PRODUCT_ID))
      ) {
        const hasTenant: ValidTenant = {
          tenantId: key,
        };

        containsProduct.push(hasTenant);
      }
    });
    if (userTenants != null) {
      const myFilteredArray = userTenants.filter(original => {
        return containsProduct.some(ft => {
          return ft.tenantId === original.tenantId;
        });
      });
      return myFilteredArray;
    }
  };

  const userHasAccessToTenant = useMemo(() => {
    if (!getStoredTenant()) {
      if (user?.tenantIds?.includes(user?.currentTenantId)) {
        return true;
      }
      return false;
    } else {
      if (user?.tenantIds?.includes(getStoredTenant())) {
        return true;
      }
      return false;
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && user?.currentTenantId) {
      if (!IS_LOCAL_AUTH && !user?.accessToken) {
        return;
      }
      refetchCurrentTenant().then(tenant => {
        const currentTenantTheme = tenant.data.theme;
        if (user?.statusCode === 200 && !user?.tenantIds.length) {
          setZeroTenantAccess(true);
          theme.setCurrentTheme(null);
        } else {
          if (!currentTenantTheme) {
            theme.setCurrentTheme(null);
          }
          if (theme.setCurrentTheme && currentTenantTheme !== undefined) {
            theme.setCurrentTheme(currentTenantTheme);
          }
        }
      });
    }
    // Don't need to include Theme as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, refetchCurrentTenant, isAuthenticated]);
  if (
    (!theme.isInitialized && isAuthenticated && !getTenantError) ||
    (isAuthenticated && user?.statusCode !== 200)
  ) {
    return <LoadingIndicator />;
  }

  if (!IS_LOCAL_AUTH && zeroTenantAccess) {
    return <ZeroTenantAccessDialog open={true} />;
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <SnackbarProvider>
        <ActionProvider>
          <Shell
            userAvatar={
              <UserWrapper
                tenant={{
                  label: 'Current Tenant',
                  current: userHasAccessToTenant ? currentTenant : null,
                  options: enableFilter(),
                  onSelect: setUser,
                }}
                unityUrl={process.env.REACT_APP_UNITY_URL}
                user={user}
              />
            }
            appContent={<AppBarControls />}
            leftMenu={userHasAccessToTenant ? leftMenu : []}
            appSelectorMenus={appSelectorMenus}
            navRoot={t('Home')}
            siteName={t('Data Browser')}
            unityUrl={process.env.REACT_APP_UNITY_URL}
            hideLayout={!isAuthenticated && IS_LOCAL_AUTH}
            languages={languages}
          >
            <ErrorBoundary>
              <Suspense fallback={<PageLoading />}>
                <Switch location={location}>
                  <Route exact path="/aad_callback" component={AadCallback} />
                  {/* If you have different types of login based on .env */}
                  {!isAuthenticated && IS_LOCAL_AUTH && (
                    <Route path="/login" exact component={LocalLogin} />
                  )}
                  <PrivateRoute exact path="/" component={DataBuilder} />
                  <Redirect exact from="/databuilder" to="/" />
                  <PrivateRoute
                    path="/recents"
                    exact
                    component={RecentQueries}
                  />
                  <PrivateRoute path="/saved" exact component={SavedQueries} />
                  <PrivateRoute
                    path="/shared"
                    exact
                    component={SharedQueries}
                  />
                  <PrivateRoute
                    path="/databuilder/:apiMethodInstanceId"
                    component={DataBuilder}
                  />
                  <PrivateRoute
                    path="/favorites"
                    exact
                    component={FavoriteQueries}
                  />
                  <PrivateRoute
                    path="/manager"
                    exact
                    component={CatalogManager}
                  />
                  <Route path="*" component={PageNotFound} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </Shell>
        </ActionProvider>
      </SnackbarProvider>
    </Router>
  );
};

export default Routes;
